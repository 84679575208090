import moment from 'moment-timezone';
import Globalize from './languages/Globalize';

export const orderStatusOptions = [
  { id: 1, name: Globalize.getString('payment_pending'), value: true },
  { id: 2, name: Globalize.getString('ordered'), value: true },
  { id: 3, name: Globalize.getString('shipped'), value: true },
  { id: 4, name: Globalize.getString('delivered'), value: true },
  { id: 5, name: Globalize.getString('cancelled'), value: true },
  { id: 6, name: Globalize.getString('exchange'), value: true },
  { id: 7, name: Globalize.getString('return'), value: true },
  { id: 8, name: Globalize.getString('refund'), value: true },
  { id: 9, name: Globalize.getString('hold'), value: true },
  { id: 10, name: Globalize.getString('re_ship'), value: true },
  { id: 11, name: Globalize.getString('dispute_con_call'), value: true },
  { id: 12, name: Globalize.getString('supplier_accepted'), value: false },
];

export const orderStatusOptionsFilter = [
  { value: 1, label: Globalize.getString('payment_pending') },
  { value: 2, label: Globalize.getString('ordered') },
  { value: 3, label: Globalize.getString('shipped') },
  { value: 4, label: Globalize.getString('delivered') },
  { value: 5, label: Globalize.getString('cancelled') },
  { value: 6, label: Globalize.getString('exchange') },
  { value: 7, label: Globalize.getString('return') },
  { value: 8, label: Globalize.getString('refund') },
  { value: 9, label: Globalize.getString('hold') },
  { value: 10, label: Globalize.getString('re_ship') },
  { value: 11, label: Globalize.getString('dispute_con_call') },
  { value: 12, label: Globalize.getString('supplier_accepted') },
];

export const supplierOrderStatusOptionsFilter = [
  { value: 12, label: Globalize.getString('supplier_accepted') },
  { value: 1, label: Globalize.getString('payment_pending') },
  { value: 2, label: Globalize.getString('ordered') },
  { value: 3, label: Globalize.getString('shipped') },
  { value: 4, label: Globalize.getString('delivered') },
  { value: 5, label: Globalize.getString('cancelled') },
  { value: 6, label: Globalize.getString('exchange') },
  { value: 7, label: Globalize.getString('return') },
  { value: 8, label: Globalize.getString('refund') },
  { value: 9, label: Globalize.getString('hold') },
  { value: 10, label: Globalize.getString('re_ship') },
  { value: 11, label: Globalize.getString('dispute_con_call') },
];

export const courierOptions = [
  {
    id: 'Delhivery',
    name: 'Delhivery',
    url: 'http://track.delhivery.com/p/[id]',
    valid: true,
  },
  {
    id: 'FedEx',
    name: 'FedEx',
    url: 'https://www.fedex.com/apps/fedextrack/?action=track&tracknumbers=[id]',
    valid: true,
  },
  {
    id: 'DTDC',
    name: 'DTDC',
    url: 'http://www.dtdc.in/tracking/shipment-tracking.asp',
    valid: false,
  },
  {
    id: 'Blue Dart',
    name: 'Blue Dart',
    url: 'https://www.bluedart.com/',
    valid: false,
  },
  {
    id: 'India Post',
    name: 'India Post',
    url: 'https://www.indiapost.gov.in/VAS/Pages/trackconsignment.aspx',
    valid: false,
  },
  {
    id: 'Professional Courier',
    name: 'Professional Courier',
    url: 'http://www.tpcindia.com/Tracking2014.aspx?id=[id]&type=0&service=0',
    valid: true,
  },
  {
    id: 'ST Courier',
    name: 'ST Courier',
    url: 'http://www.stcourier.com/home.php',
    valid: false,
  },
  {
    id: 'TrackOn Courier',
    name: 'TrackOn Courier',
    url: 'http://trackoncourier.com/track1.aspx',
    valid: false,
  },
  {
    id: 'Shree Anjani Courier',
    name: 'Shree Anjani Courier',
    url: 'http://shreeanjanicourier.com/',
    valid: false,
  },
  {
    id: 'Bookmypacket',
    name: 'Bookmypacket',
    url: 'http://www.bookmypacket.com',
    valid: false,
  },
  {
    id: 'Shree Maruti Courier',
    name: 'Shree Maruti Courier',
    url: 'http://www.shreemaruticourier.com/track-your-shipment/?tracking_id=[id]',
    valid: true,
  },
  {
    id: 'Shree Tirupati Courier',
    name: 'Shree Tirupati Courier',
    url: 'http://www.shreetirupaticourier.net/Frm_DocTrack.aspx?docno=214314142343&Tmp=[id]',
    valid: true,
  },
  {
    id: 'First Flight',
    name: 'First Flight',
    url: 'http://www.firstflight.net/trackingrequest.php?consRadio=C&trackingtextbox=[id]',
    valid: true,
  },
  {
    id: 'West India Courier',
    name: 'West India Courier',
    url: '',
    valid: false,
  },
  { id: 'Other', name: 'Other', url: '', valid: false },
];

export const catalogTypeOptions = [
  { id: 'catalog', name: Globalize.getString('catalog') },
  { id: 'non-catalog', name: Globalize.getString('non_catalog') },
];

export const paymentMode = [
  {
    value: 'bank_transfer',
    label: Globalize.getString('bank_transfer'),
    disable: true,
  },
  { value: 'cod', label: Globalize.getString('cod'), disable: true },
  { value: 'online', label: Globalize.getString('online'), disable: true },
  { value: 'paytm', label: Globalize.getString('paytm'), disable: true },
  {
    value: 'credits',
    label: Globalize.getString('credits_wallet'),
    disable: true,
  },
];

export const returnType = [
  { id: 'pending', name: Globalize.getString('pending') },
  { id: 'picked_up', name: Globalize.getString('picked_up') },
  { id: 'delivered', name: Globalize.getString('delivered') },
];

export const exchangeType = [
  { id: 'return_pending', name: Globalize.getString('return_pending') },
  {
    id: 'return_processed',
    name: Globalize.getString('return_picked_up_or_delivered'),
  },
  {
    id: 'forward_dispatched',
    name: Globalize.getString('exchange_dispatched'),
  },
  { id: 'forward_delivered', name: Globalize.getString('exchange_delivered') },
];

export const pickupConfirmationState = [
  { value: 1, label: Globalize.getString('yes') },
  { value: 2, label: Globalize.getString('no') },
  { value: 3, label: Globalize.getString('maybe') },
  { value: 4, label: Globalize.getString('done') },
];

export const cancelReasons = [
  { id: 1, name: Globalize.getString('product_not_available'), value: true },
  { id: 2, name: Globalize.getString('cancelled_by_reseller'), value: true },
  { id: 3, name: Globalize.getString('quality_issue'), value: true },
  {
    id: 4,
    name: Globalize.getString('test_order_product_cancelled'),
    value: true,
  },
  {
    id: 5,
    name: Globalize.getString('test_order_order_cancelled'),
    value: false,
  },
  {
    id: 6,
    name: Globalize.getString('placed_multiple_duplicate_orders'),
    value: true,
  },
  { id: 7, name: Globalize.getString('reseller_unresponsive'), value: true },
  { id: 8, name: Globalize.getString('bank_transfer_pending'), value: true },
  { id: 9, name: Globalize.getString('cod_verification_pending'), value: true },
  {
    id: 10,
    name: Globalize.getString('delayed_delivery_shipping'),
    value: true,
  },
  { id: 11, name: Globalize.getString('pincode_not_serviceable'), value: true },
  { id: 12, name: Globalize.getString('wants_to_change_address'), value: true },
  {
    id: 13,
    name: Globalize.getString('exchange_product_not_available'),
    value: true,
  },
  { id: 14, name: Globalize.getString('change_in_supplier'), value: true },
  {
    id: 15,
    name: Globalize.getString('rto_returned_to_supplier'),
    value: true,
  },
  { id: 16, name: Globalize.getString('better_price_available'), value: true },
  {
    id: 17,
    name: Globalize.getString('customer_changed_his_her_mind'),
    value: true,
  },
  {
    id: 18,
    name: Globalize.getString('unhappy_with_delivery_time'),
    value: true,
  },
  {
    id: 19,
    name: Globalize.getString('wants_to_avail_discounts'),
    value: true,
  },
  {
    id: 20,
    name: Globalize.getString('wants_to_change_contact_number'),
    value: true,
  },
  { id: 21, name: Globalize.getString('order_placed_by_mistake'), value: true },
  {
    id: 22,
    name: Globalize.getString('wants_to_change_payment_mode'),
    value: true,
  },
  {
    id: 23,
    name: Globalize.getString('unaware_of_placing_order'),
    value: true,
  },
  {
    id: 24,
    name: Globalize.getString('unhappy_with_previous_order'),
    value: true,
  },
  { id: 25, name: Globalize.getString('wants_to_change_size'), value: true },
  { id: 26, name: Globalize.getString('wants_to_change_color'), value: true },
  { id: 27, name: Globalize.getString('other'), value: false },
  {
    id: 28,
    name: Globalize.getString('sla_breached_by_supplier'),
    value: true,
  },
  {
    id: 29,
    name: Globalize.getString('sla_breached_by_supplier_with_compensation'),
    value: false,
  },
  {
    id: 30,
    name: Globalize.getString('delayed_delivery_shipping_without_bonus'),
    value: false,
  },
  {
    id: 31,
    name: Globalize.getString('fraud_orders_fake_orders'),
    value: true,
  },
  { id: 32, name: Globalize.getString('out_of_stock'), value: true },
  { id: 33, name: 'Shipment Package Lost', value: true },
  { id: 35, name: 'High RTO users', value: true },
  { id: 36, name: 'High COD unverified', value: true },
  { id: 37, name: 'Wrong or Incomplete Address', value: true },
  { id: 38, name: 'Call & Confirm', value: true },
  { id: 50, name: 'Last Mile Red Zone', value: true },
  { id: 51, name: 'First Mile Red Zone', value: true },
  { id: 52, name: 'Direct Cancellation of DS model orders', value: true },
  { id: 53, name: 'Fraud Supplier Cancellation', value: true },
  { id: 54, name: 'Ops Cancellation', value: true },
  { id: 55, name: 'Fraud orders / Out of stock', value: true },
  { id: 56, name: 'Fraud orders / COD block', value: true },
  { id: 57, name: 'Long Tail supplier', value: true },
  { id: 58, name: 'Risky COD Order', value: true },
  { id: 59, name: 'CNCF Reject', value: true },
];

export const returnExchangeStatus = [
  { id: 1, name: Globalize.getString('initiated') },
  { id: 2, name: Globalize.getString('accepted') },
  { id: 3, name: Globalize.getString('rejected') },
  { id: 4, name: Globalize.getString('cancelled') },
  { id: 5, name: Globalize.getString('re_initiated') },
  { id: 6, name: Globalize.getString('cancelled_by_delhivery') },
];

export const rejection_return_reason = [
  { id: 1, name: Globalize.getString('it_doesnt_meet_our_return_policy') },
  {
    id: 2,
    name: Globalize.getString('the_product_is_not_in_original_condition'),
  },
  {
    id: 3,
    name: Globalize.getString(
      'the_request_has_been_raised_late_per_our_return_policy'
    ),
  },
  { id: 4, name: Globalize.getString('per_your_request') },
  {
    id: 6,
    name: Globalize.getString(
      'you_selected_the_incorrect_return_reason_kindly_reinitiate_the_return_with_the_correct_reason_and_corresponding_evidence'
    ),
  },
  {
    id: 7,
    name: Globalize.getString(
      'image_uploaded_by_you_is_not_that_of_an_actual_product_kindly_re_initiate_the_return_by_uploading_the_picture_of_the_delivered_product'
    ),
  },
  { id: 5, name: Globalize.getString('others') },
];

export const rejection_exchange_reason = [
  { id: 1, name: Globalize.getString('it_doesnt_meet_our_exchange_policy') },
  {
    id: 2,
    name: Globalize.getString('the_requested_product_is_not_available'),
  },
  {
    id: 4,
    name: Globalize.getString(
      'you_selected_the_incorrect_return_reason_kindly_reinitiate_the_return_with_the_correct_reason_and_corresponding_evidence'
    ),
  },
  {
    id: 5,
    name: Globalize.getString(
      'image_uploaded_by_you_is_not_that_of_an_actual_product_kindly_re_initiate_the_return_by_uploading_the_picture_of_the_delivered_product'
    ),
  },
  { id: 3, name: Globalize.getString('others') },
];

export const audience_count = 140;

export const reseller_verification_status = [
  { id: 0, value: Globalize.getString('unprocessed') },
  { id: 1, value: Globalize.getString('flagged_as_reseller') },
  { id: 2, value: Globalize.getString('flagged_as_customer') },
  { id: 3, value: Globalize.getString('blocked_as_customer') },
  { id: 4, value: Globalize.getString('unflagged_from_customer') },
];

export const banners_screen = [
  { id: 'no_screen', value: Globalize.getString('no_screen') },
  { id: 'referral_program', value: Globalize.getString('referral_program') },
  { id: 'referral_details', value: Globalize.getString('referral_details') },
  { id: 'catalog', value: Globalize.getString('catalog_screen') },
  { id: 'challenges', value: Globalize.getString('challenges_screen') },
  { id: 'bonus_tracker', value: Globalize.getString('bonus_tracker') },
  { id: 'single_collection', value: Globalize.getString('collection_screen') },
  { id: 'web_view', value: Globalize.getString('web_view') },
  { id: 'app_update', value: Globalize.getString('app_update') },
  { id: 'complete_profile', value: Globalize.getString('complete_profile') },
  { id: 'browser', value: Globalize.getString('browser') },
  { id: 'landing_page', value: Globalize.getString('landing_page') },
  { id: 'community', value: Globalize.getString('community') },
  { id: 'training', value: Globalize.getString('training') },
  {
    id: 'travel_landing_page',
    value: Globalize.getString('travel_landing_page'),
  },
  { id: 'spin_rewards', value: Globalize.getString('spin_rewards') },
  { id: 'journey', value: Globalize.getString('journey') },
  { id: 'social_profile', value: Globalize.getString('social_profile') },
  {
    id: 'earnings_main_page',
    value: Globalize.getString('earnings_main_page'),
  },
  { id: 'margin_earnings', value: Globalize.getString('margin_earnings') },
  { id: 'referral_earnings', value: Globalize.getString('referral_earnings') },
  { id: 'farmiso', value: Globalize.getString('farmiso') },
  {
    id: 'catalog_listing_page',
    value: Globalize.getString('catalog_listing_page'),
  },
  { id: 'coins_landing_page', value: 'Coins Landing Page' },
  { id: 'visual_search_page', value: 'Visual Search Page' },
  { id: 'visual_search_intro_page', value: 'Visual Search Intro Page' },
  { id: 'mb_landing_page', value: Globalize.getString('mb_landing_page') },
  { id: 'bnpl_webview', value: 'BNPL Webview' },
];

export const deeplink_screen = [
  { id: 'referral_program', value: Globalize.getString('referral_program') },
  { id: 'referral_details', value: Globalize.getString('referral_details') },
  { id: 'catalog', value: Globalize.getString('catalog_screen') },
  { id: 'challenges', value: Globalize.getString('challenges_screen') },
  { id: 'bonus_tracker', value: Globalize.getString('bonus_tracker') },
  { id: 'single_collection', value: Globalize.getString('collection_screen') },
  { id: 'web_view', value: Globalize.getString('web_view') },
  { id: 'app_update', value: Globalize.getString('app_update') },
  { id: 'complete_profile', value: Globalize.getString('complete_profile') },
  { id: 'browser', value: Globalize.getString('browser') },
  { id: 'community', value: Globalize.getString('community') },
  { id: 'training', value: Globalize.getString('training') },
  {
    id: 'travel_landing_page',
    value: Globalize.getString('travel_landing_page'),
  },
  { id: 'spin_rewards', value: Globalize.getString('spin_rewards') },
  { id: 'join_mentorship', value: Globalize.getString('join_mentorship') },
];

export const streak_intervals = [
  { id: 'HOUR', value: Globalize.getString('hour') },
  { id: 'DAY', value: Globalize.getString('day') },
  { id: 'WEEK', value: Globalize.getString('week') },
  { id: 'MONTH', value: Globalize.getString('month') },
];

export function twoDigitNumber(n) {
  return n > 9 ? '' + n : '0' + n;
}

export const referral_payment_status = [
  { id: 1, value: Globalize.getString('scheduled') },
  { id: 2, value: Globalize.getString('failed') },
  { id: 3, value: Globalize.getString('paid') },
  { id: 4, value: Globalize.getString('paid') },
  { id: 6, value: Globalize.getString('failed') },
];

export function validNumber(num, min, max) {
  if (num === undefined || num === null) {
    return false;
  }
  let parsed = Number.parseInt(num);
  if (Number.isNaN(parsed)) {
    return false;
  }
  if (min !== undefined && min > num) {
    return false;
  }
  return !(max !== undefined && max < num);
}

export const returnOptions = [
  { id: 0, name: Globalize.getString('no_returns') },
  { id: 1, name: Globalize.getString('returns') },
  { id: 2, name: Globalize.getString('exchange_only') },
];

export const pickUpOptions = [
  { id: 1, name: Globalize.getString('yes') },
  { id: 2, name: Globalize.getString('no') },
  { id: 3, name: Globalize.getString('maybe') },
  { id: 4, name: Globalize.getString('done') },
];

export const verify_options = [
  { id: 'yes', name: Globalize.getString('yes') },
  { id: 'no', name: Globalize.getString('no') },
];

export const reship_reasons = [
  { id: 1, name: Globalize.getString('rto') },
  { id: 2, name: Globalize.getString('product_missing') },
];

export const exchange_supplier_status = [
  { id: 1, name: Globalize.getString('pending') },
  { id: 2, name: Globalize.getString('to_be_dispatched') },
  { id: 3, name: Globalize.getString('ready_to_ship') },
  { id: 4, name: Globalize.getString('shipped') },
  { id: 5, name: Globalize.getString('cancelled') },
];

export const supplier_quality_check_state = [
  { id: 1, value: Globalize.getString('qc_testing') },
  { id: 2, value: Globalize.getString('qc_sampling') },
  { id: 3, value: Globalize.getString('acceptable_quality') },
  { id: 4, value: Globalize.getString('qc_deactivation') },
  { id: 5, value: Globalize.getString('acceptable_quality_ncb') },
  { id: 6, value: Globalize.getString('qc_reactivation') },
  { id: 7, value: Globalize.getString('qc_testing_under_review') },
  { id: 8, value: Globalize.getString('qc_reactivation_under_review') },
  { id: 9, value: Globalize.getString('edge_suppliers') },
  { id: 10, value: Globalize.getString('beauty_qc_testing') },
  { id: 11, value: Globalize.getString('qc_testing_dropped') },
  { id: 12, value: Globalize.getString('supplier_cancellation_deactivation') },
  { id: 13, value: Globalize.getString('fraud_supplier_deactivation') },
];

export const address_country = [
  { id: 'India', name: 'India' }
];

export const address_state = [
  { value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
  { value: 'Arunachal Pradesh', label: 'Arunachal Pradesh' },
  {
    value: 'Andaman and Nicobar Islands',
    label: 'Andaman and Nicobar Islands',
  },
  { value: 'Assam', label: 'Assam' },
  { value: 'Bihar', label: 'Bihar' },
  { value: 'Chandigarh', label: 'Chandigarh' },
  { value: 'Chhattisgarh', label: 'Chhattisgarh' },
  { value: 'Delhi', label: 'Delhi' },
  {
    value: 'Dadra and Nagar Haveli and Daman and Diu',
    label: 'Dadra and Nagar Haveli and Daman and Diu',
  },
  { value: 'Goa', label: 'Goa' },
  { value: 'Gujarat', label: 'Gujarat' },
  { value: 'Haryana', label: 'Haryana' },
  { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
  { value: 'Jammu and Kashmir', label: 'Jammu and Kashmir' },
  { value: 'Jharkhand', label: 'Jharkhand' },
  { value: 'Karnataka', label: 'Karnataka' },
  { value: 'Kerala', label: 'Kerala' },
  { value: 'Ladakh', label: 'Ladakh' },
  { value: 'Lakshadweep', label: 'Lakshadweep' },
  { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
  { value: 'Maharashtra', label: 'Maharashtra' },
  { value: 'Manipur', label: 'Manipur' },
  { value: 'Meghalaya', label: 'Meghalaya' },
  { value: 'Mizoram', label: 'Mizoram' },
  { value: 'Nagaland', label: 'Nagaland' },
  { value: 'Odisha', label: 'Odisha' },
  { value: 'Punjab', label: 'Punjab' },
  { value: 'Puducherry', label: 'Puducherry' },
  { value: 'Rajasthan', label: 'Rajasthan' },
  { value: 'Sikkim', label: 'Sikkim' },
  { value: 'Tamil Nadu', label: 'Tamil Nadu' },
  { value: 'Telangana', label: 'Telangana' },
  { value: 'Tripura', label: 'Tripura' },
  { value: 'Uttarakhand', label: 'Uttarakhand' },
  { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
  { value: 'West Bengal', label: 'West Bengal' },
];

export const kycVerificationStatus = [
  {
    label: Globalize.getString('pending'),
    value: 'pending',
  },
  {
    label: Globalize.getString('approved'),
    value: 'approved',
  },
  {
    label: Globalize.getString('rejected'),
    value: 'rejected',
  },
];

export const fileUploadProgressStatus = {
  COMPLETE: 'completed',
  START: 'started',
  FAIL: 'failure',
  SUCCESS: 'success',
};

export function validateEmail(email) {
  let re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/gim;
  return re.test(email);
}

export function removeNewLine(inputValue) {
  let output = inputValue;
  output = output.replace(/\s/g, ' ');
  return output;
}

export function validatePassword(password) {
  let regularExpression =
    /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
  return regularExpression.test(password);
}

export function find_duplicate_in_array(arr) {
  let i,
    len = arr.length,
    result = [],
    obj = {};
  for (i = 0; i < len; i++) {
    obj[arr[i]] = 0;
  }
  for (i in obj) {
    result.push(i);
  }
  return result;
}

export const ops_status = [
  { id: 1, value: Globalize.getString('none') },
  { id: 2, value: Globalize.getString('for_you_exclusion') },
];

export const supplier_types = [
  {
    id: 'manufacturer',
    value: Globalize.getString('manufacturer'),
    valid: false,
  },
  { id: 'wholesaler', value: Globalize.getString('wholesaler'), valid: false },
  { id: 'hybrid', value: Globalize.getString('hybrid'), valid: false },
  {
    id: 'Big Manufacturers',
    value: Globalize.getString('big_manufacturers'),
    valid: true,
  },
  {
    id: 'Medium Manufacturers',
    value: Globalize.getString('medium_manufacturers'),
    valid: true,
  },
  {
    id: 'Small Manufacturers',
    value: Globalize.getString('small_manufacturers'),
    valid: true,
  },
  {
    id: 'Big Wholesalers',
    value: Globalize.getString('big_wholesalers'),
    valid: true,
  },
  {
    id: 'Medium Wholesaler',
    value: Globalize.getString('medium_wholesalers'),
    valid: true,
  },
  {
    id: 'Small Wholesalers',
    value: Globalize.getString('small_wholesalers'),
    valid: true,
  },
];

export function replaceFirstAndLastLinebreaks(str) {
  return str.trim().replace(/^\s+|\s+$/g, '');
}

export function replaceLineBreaks(str) {
  return str.trim().replace(/\s+/g, ' ');
}

export function parseStringTOBoolean(v) {
  return v === 'false' ||
    v === 'null' ||
    v === 'NaN' ||
    v === 'undefined' ||
    v === '0'
    ? false
    : !!v;
}

export function replaceLineBreaksCommoSaprate(str) {
  return str.trim().replace(/\n/g, ',');
}

export function convertMultipleLinesToJavascriptArray(str) {
  let lines = str.split(/\n/);
  let output = [];
  for (let i = 0; i < lines.length; i++) {
    if (/\S/.test(lines[i])) {
      output.push(lines[i].trim());
    }
  }
  return output;
}

export function titleCase(str) {
  return str
    .split(' ')
    .map((val) => {
      return val.charAt(0).toUpperCase() + val.substr(1).toLowerCase();
    })
    .join(' ');
}

export function stringToBoolean(string) {
  if (typeof string !== 'boolean') {
    switch (string.toLowerCase().trim()) {
      case true:
      case 'true':
      case 'yes':
      case '1':
        return true;
      case false:
      case 'false':
      case 'no':
      case '0':
      case null:
        return false;
      default:
        return Boolean(string);
    }
  } else {
    return string;
  }
}

export function getUserPageAccess(roles, page_roles) {
  let valid = false;
  roles.forEach(function (role) {
    if (page_roles.includes(role)) {
      valid = true;
    }
  });
  return valid;
}

/**
 * @return {boolean}
 */
export function ValidURL(str) {
  let regex =
    /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
  return regex.test(str);
}

export function isNumeric(input) {
  return input - 0 == input && ('' + input).trim().length >= 0;
}

export function toFixedTrunc(value, n) {
  const f = Math.pow(10, n);
  return (Math.trunc(value * f) / f).toFixed(n);
}

export const aws_cdn_prefix = 'https://static.meeshosupply.com';

export function isAlphaNumeric(input) {
  let regex = /^[a-zA-Z0-9]+$/;
  return regex.test(input);
}

export function isValidDate(str) {
  return moment(str, 'YYYY-MM-DD', true).isValid();
}

export function removeEmpty(obj) {
  Object.keys(obj).forEach(
    (k) =>
      (obj[k] && typeof obj[k] === 'object' && removeEmpty(obj[k])) ||
      (obj[k] === null && obj[k] !== undefined && delete obj[k])
  );
  return obj;
}

/**
 * @return {boolean}
 */
export function IsJsonString(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

export const exchange_unavailable_messages = [
  {
    id: Globalize.getString(
      'exchange_is_not_allowed_for_international_products'
    ),
    name: Globalize.getString(
      'exchange_is_not_allowed_for_international_products'
    ),
  },
  {
    id: Globalize.getString('exchange_is_not_allowed_for_cpg_products'),
    name: Globalize.getString('exchange_is_not_allowed_for_cpg_products'),
  },
  {
    id: Globalize.getString('exchange_is_not_allowed_for_this_product'),
    name: Globalize.getString('exchange_is_not_allowed_for_this_product'),
  },
];

// export const linkToSMSGuideLines = 'https://www.vilpower.in/static/link_documents/manualDownloads/ContentTemplateGuidelines.pdf';
export const linkToSMSGuideLines =
  'https://docs.google.com/document/d/1lESeApgcvr5mZOPG0hj4_UJnasyjFBFKbnb4oJ5WZZM/edit?usp=sharing';

export const callPurpose = {
  supplier: [
    'BD/BRM Team',
    'AM Team',
    'Ops Team',
    'Internal Research',
    'Others',
  ],
  reseller: ['Mission Rise', 'Bachat Bazzar', 'Internal Research', 'Others'],
};

export const sampleTemplateContent = {
  templateName: 'Reseller Bank Details 20190915',
  smsContent:
    'Dear ${VAR1},\nYour ${VAR2} of Rs. ${VAR3} is pending with Meesho. Please fill bank details here to get it. ${VAR4}',
  language: 'ENGLISH',
  smsType: 'TRANSACTIONAL',
  senderId: 'MSHOTP',
  campaign: false,
  service: 'Referrals',
};

export const sampleTemplateNotificationContent = {
  template_name: 'community_notification',
  template_title: Globalize.getString('notification_sample_title'),
  custom_message_body: Globalize.getString('notification_sample_body'),
  metadata: [
    {
      key: 'screen',
      value: 'community',
    },
    {
      key: 'community_url',
      value: 'http://community.meesho.com',
    },
  ],
};

export const initialMetadata = [{ key: '', value: '' }];

export const WhatsAppSampleTemplateNotificationContent = {
  template_name: 'community_notification',
  custom_message_body: Globalize.getString('notification_sample_body'),
  interactive: 'false',
  message_type: 'TEXT',
};

export const notificationScreen = [
  Globalize.getString('for_you'),
  Globalize.getString('web_view'),
  Globalize.getString('earnings_main_page'),
  Globalize.getString('margin_earnings'),
  Globalize.getString('referral_earnings'),
];

export function downloadFileFromStream(data, filename, mime) {
  let blob = new Blob([data], { type: mime || 'application/octet-stream' });
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    let blobURL = window.URL.createObjectURL(blob);
    let tempLink = document.createElement('a');
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);
    tempLink.setAttribute('target', '_blank');
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  }
}

export const videoReviewStatus = [
  { value: 0, label: Globalize.getString('pending') },
  { value: 1, label: Globalize.getString('approved') },
  { value: 2, label: Globalize.getString('rejected') },
];

export const REJECTION_REASON = [
  { value: 4, label: 'Low Media Quality' },
  { value: 3, label: 'Wrong Media Uploaded' },
  { value: 2, label: 'Unethical Media Uploaded' },
  { value: 1, label: 'Duplicate/Multiple Media Uploaded' },
  { value: 0, label: 'Others' },
];

export const algoliaDebounceDelay = 300;

export const ruleEngineLimit = 20;

export const customerCODAmount =
  Globalize.getCountry() === 'ID' ? 1500000 : 5000;

export const paddingCheckboxList = [
  { title: 'No', isSelected: true, value: 0 },
  { title: 'Yes', isSelected: false, value: 1 },
];

export const roundedCornerCheckboxList = [
  { title: 'No', isSelected: true, value: 0 },
  { title: 'Yes', isSelected: false, value: 1 },
];

export const widgetShowTimerColorList = [
  { label: 'Red', name: 'Red', value: 'f5222d', isSelected: false },
  { label: 'Blue', name: 'Blue', value: '2e65e5', isSelected: false },
  { label: 'Violet', name: 'Violet', value: '554fb2', isSelected: false },
];
export const bannerCategories = [
  { title: 'Others', value: 'fy' },
  { title: 'Community', value: 'community' },
];

//agent dashboard constants

export const paginationLimit = {
  two: 2,
  five: 5,
  ten: 10,
};

export const bonusTab = {
  bonus_view_details_limit: 10,
};

export const mailTemplate = {
  // message: 'AGENT_DASHBOARD_MESSAGE_TEMPLATE_LATEST',
  message: 'AGENT_DASHBOARD_MESSAGE_TEMPLATE',
  // invoice: 'AGENT_DASHBOARD_INVOICE_TEMPLATE_LATEST'
  invoice: 'AGENT_DASHBOARD_INVOICE_TEMPLATE',
};

export const offset = 0;

export const itemLimit = {
  two: 2,
  five: 5,
  ten: 10,
};

export const groupId = 'TECH';

export const priority = {
  low: 'LOW',
  high: 'HIGH',
  immediate: 'IMMEDIATE',
};

export const status = {
  open: 'OPEN',
  close: 'CLOSE',
};

export const source = 'PORTAL';

export const orderConstant = {
  limit: 10,
  initial_offset: 0,
  filter_status: 0,
};

export const paymentFileUploadFilterStatus = [
  {
    label: Globalize.getString('all'),
    value: '',
  },
  {
    label: Globalize.getString('pending'),
    value: 'PENDING',
  },
  {
    label: Globalize.getString('processing'),
    value: 'PROCESSING',
  },
  {
    label: Globalize.getString('failed'),
    value: 'FAILED',
  },
  {
    label: Globalize.getString('completed'),
    value: 'COMPLETED',
  },
  {
    label: Globalize.getString('uploaded'),
    value: 'UPLOADED',
  },
];
export const serviceabilityFileUploadDetails = {
  pin_code_file_upload: {
    label: 'Pin Code',
    id: 'pin_code',
    uploadUrl: 'pin-code',
  },
  zone_info_file_upload: {
    label: 'Zone Info',
    id: 'zone_info',
    uploadUrl: 'zone-info',
  },
  time_map_file_upload: {
    label: 'Time Map',
    id: 'time_map',
    uploadUrl: 'time-map',
  },
  tat_map_file_upload: {
    label: 'TAT Map',
    id: 'TAT_map',
    uploadUrl: 'tat-map',
  },
  city_city_lane_map_file_upload: {
    label: 'City City Lane Map',
    id: 'city_city_lane_map',
    uploadUrl: 'city-city-lane-map',
  },
  update_supplier_file_upload: {
    label: 'Update Supplier',
    id: 'update_supplier',
    uploadUrl: 'supplier',
  },
  reverse_zone_info_file_upload: {
    label: 'Reverse Zone',
    id: 'reverrse_zone',
    uploadUrl: 'reverse-zone-info',
  },
  city_city_zone_map_file_upload: {
    label: 'City City Zone Map',
    id: 'city_city_zone_map',
    uploadUrl: 'city-city-zone-map',
  },
  holidays_file_upload: {
    label: 'Holidays',
    id: 'holidays',
    uploadUrl: 'holidays',
  },
  delays_file_upload: {
    label: 'Delays',
    id: 'delays',
    uploadUrl: 'delays',
  },
  sunday_promises_file_upload: {
    label: 'Sunday Promises',
    id: 'sunday_promises',
    uploadUrl: 'sunday-promises',
  },
  hub_address_map_file_upload: {
    label: 'Hub Address',
    id: 'hub_address',
    uploadUrl: 'hub-address-map',
  },
  address_data_file_upload: {
    label: 'Address Data',
    id: 'address_data',
    uploadUrl: 'address-data',
  },
};

export const pathnames = {
  resellerPaymentFiles: '/reseller/payment/files',
  supplierPaymentFiles: '/supplier/payment',
};

export const logPIScreenName = {
  ORDER_DETAILS: 'Order Details',
  RESELLER_INFO: 'Reseller Info',
  CUSTOMER_ADDRESS: 'Customer Address',
  SUPPLIER_INFO: 'Supplier Info',
  SUPPLIER_TO_RESELLER_INVOICE_DOWNLOADED:
    'Supplier to reseller invoice downloaded',
  RESELLER_SEARCH: 'Reseller Search',
  SUPPLIER_SEARCH: 'Supplier Search',
  RESELLER_BANK_DETAILS: 'Reseller Bank Details',
  RESELLER_REFERRAL_DETAILS: 'Reseller Referral Details',
  SUPPLIER_DETAILS: 'Supplier Details',
};

export const uploadWidgetImageType = {
  WIDGET: 'WIDGET', // widget image
  WIDGET_GROUP_ICON: 'WIDGET_GROUP_ICON', // widget group icon image(newly added)
  WIDGET_GROUP_OFFER_ICON: 'WIDGET_GROUP_OFFER_ICON', // offer icon for hot deals (newly added)
};

export const audience_renewal_freq_fetch =
  '/api/admin/audience/renewal-freq/fetch';
export const audience_delete = '/api/admin/audience/renewal/stop';
export const fetch_audience_modId = '/api/admin/audience/modId';
export const audience_refresh = '/api/admin/audience/refreshAudience';

export const audience_data_limit = 100000;
export const DELETED_EXPIRED_AUDIENCE_MSG =
  'Note : This Audience has been deleted/expired. if you still want it, you need to create new Audience.';

export const EDIT_REFRESH_QUERY_MSG =
  'Note : Any changes in the query will be reflected after the completion of the current refresh cycle.';

export const RENEWAL_STATUS = {
  RENEWED: 'RENEWED',
  STOPPED: 'STOPPED',
  EXPIRED: 'EXPIRED',
};

export const AUDIENCE_STATUS = {
  REFRESHED: 'Refreshed',
  INQUEUE: 'In Queue'
};

export const AUDIENCE_TYPE = {
  DYNAMIC: 'DYNAMIC',
  AUTOMATED: 'AUTOMATIC'
};

export const DELETE_BUTTON = 'Delete';
export const SUBMIT_RENEW_BUTTON = 'Submit/Renew';

export const SQL_QUERY = 'sql_query';
export const MODS = 'mods';

export const BANNER_SCREENS = {
  'MEESHO_BALANCE': 'mb_landing_page'
};

export const BANNER_TYPES = {
  DEFAULT: 'DEFAULT' ,
  ADS: 'ADS' ,
  LC_CATEGORY: 'LC_CATEGORY'
};
